import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminEarning.css'
import HOC from '../../Components/HOC/HOC'

import { IoSearch } from "react-icons/io5";

import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import CustomPagination from '../../Components/Pagination/Pagination';



// import img from '../../Images/img5.png'


const AdminEarning = () => {
    const [transactionData, setTransactionData] = useState([]);
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const limit = 20;
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        fetchTransactionData();
    }, [selectedServiceType, page, limit, search]);



    const fetchTransactionData = () => {
        let apiUrl = `${BaseUrl}api/v1/getAllBookingTransaction?page=${page}&limit=${limit}&search=${search}&`;

        if (selectedServiceType) {
            apiUrl += `type=${selectedServiceType}`;
        }

        axios.get(apiUrl, getAuthHeaders())
            .then(response => {
                setTransactionData(response.data.data.docs);
                setTotalPages(response.data.data.pages);
            })
            .catch(error => {
                console.error('Error fetching transaction data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) return;
        setPage(newPage);
        setLoading(true);
    };


    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };

    const handleServiceTypeChange = (event) => {
        setSelectedServiceType(event.target.value);
    };


    const handleExport = () => {
        if (!fromDate || !toDate) {
            toast.error('Please select both From Date and To Date.');
            return;
        }
    
        const adjustedToDate = new Date(toDate);
        adjustedToDate.setDate(adjustedToDate.getDate() + 1);
        const formattedToDate = adjustedToDate.toISOString().split('T')[0];
    
        const exportUrl = `${BaseUrl}api/v1/downloadBookingPayment?type=${selectedServiceType}&fromDate=${fromDate}&toDate=${formattedToDate}`;
    
        axios.get(exportUrl, getAuthHeaders())
            .then(response => {
                let downloadUrl = response.data.data;
    
                // Check if the URL is relative and prepend the base URL
                if (downloadUrl.startsWith('./')) {
                    downloadUrl = `https://tripriderapp.in/api${downloadUrl.slice(1)}`;
                }
    
                window.open(downloadUrl, '_blank');
            })
            .catch(error => {
                console.error('Error exporting data:', error);
                toast.error('Failed to export data. Please try again later.');
            });
    };
    





    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedDate = `${date.getDate().toString().padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

        return `${formattedDate} `;
    };


    const formatTime = (dateString) => {
        const date = new Date(dateString);


        let hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        const formattedTime = `${hours.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}${ampm}`;

        return `${formattedTime} `;
    };



    const handleFromDate = (event) => setFromDate(event.target.value);
    const handleToDate = (event) => setToDate(event.target.value);


    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>All Transaction</h6>
                        </div>

                        <div className='rider4'>
                            <div className='adminearning'>
                                <select value={selectedServiceType} onChange={handleServiceTypeChange}>
                                    <option value="">All Services</option>
                                    <option value="superCar">superCar</option>
                                    <option value="vehicleAmbulance">vehicleAmbulance</option>
                                    <option value="vehicle">vehicle</option>
                                </select>
                            </div>
                            <div className="date-filters">
                                <label>
                                    From:
                                    <input type="date" value={fromDate} onChange={handleFromDate} />
                                </label>
                                <label>
                                    To:
                                    <input type="date" value={toDate} onChange={handleToDate} />
                                </label>
                            </div>
                            <button onClick={handleExport}>Export</button>
                            <div className='rider5'>
                                <div className='rider6'>
                                    <IoSearch />
                                </div>
                                <input type="search" name="" id=""
                                    placeholder='Search booking'
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Booking ID</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>User</th>
                                    <th>Driver</th>
                                    <th>Service Type</th>
                                    <th>(₹)Amount</th>
                                    <th>(₹)Driver Amount</th>
                                    <th>(₹)Admin Amount</th>
                                    <th>paymentMode</th>
                                    <th>Transaction Status</th>
                                    {/* <th>Action Buttons</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="12" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading transaction...</td>
                                    </tr>
                                ) : transactionData?.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Transaction not found</td>
                                    </tr>
                                ) : (
                                    transactionData.map(transaction => (
                                        <tr key={transaction.id}>
                                            <td>{transaction.id}</td>
                                            <td>{transaction?.bookingId?.bookingId}</td>
                                            <td>{formatDate(transaction.createdAt)}</td>
                                            <td>{formatTime(transaction.createdAt)}</td>
                                            <td>
                                                {transaction?.user?.name?.length > 15 ? `${transaction?.user?.name.substring(0, 15)}....` : transaction?.user?.name}
                                            </td>
                                            <td>
                                                {transaction?.driverId?.name?.length > 15 ? `${transaction?.driverId?.name.substring(0, 15)}....` : transaction?.driverId?.name}
                                            </td>
                                            <td>{transaction?.bookingId?.serviceType}</td>
                                            <td>{transaction?.amount?.toFixed(2)}</td>
                                            <td>{transaction?.driverAmount.toFixed(2)}</td>
                                            <td>{transaction?.adminAmount.toFixed(2)}</td>
                                            <td>{transaction?.paymentMode}</td>
                                            <td>{transaction?.transactionStatus}</td>
                                        </tr>
                                    ))

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='rider_details555'>
                    <CustomPagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HOC(AdminEarning)