import React, { useEffect, useState } from 'react';
import './Login.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import img from '../../Images/logo.png';
import { BaseUrl } from '../../Components/BaseUrl/BaseUrl';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('superAdmin');
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleForgetPassword = async () => {
        try {
            if (!email) {
                setEmailError(true);
                toast.error('Please input your email!');
                return;
            }

            const response = await axios.post(`${BaseUrl}api/v1/admin/forgetPassword`, {
                email: email,
                role: role
            });

            const { _id } = response.data.data;
            toast.success("OTP sent successfully");
            navigate(`/otpverify/${_id}`);
        } catch (error) {
            console.error('Error:', error.message);
            if (error.response && error.response.data) {
                const { msg } = error.response.data;

                if (msg === 'user not found ! not registered') {
                    toast.error('Admin does not exist. Please use a different email.');
                } else if (msg === 'Please use same email id as signup!') {
                    toast.error('Please use the same email id as signup!');
                } else {
                    toast.error('Something went wrong. Please try again.');
                }
            } else {
                toast.error('Something went wrong. Please try again.');
            }
        }
    };


    return (
        <>
            <div className='login'>
                <div className='login1'>
                    <div className='login9'>
                        <img src={img} alt="" />
                    </div>
                    <div className='login10'>
                        <h3>Explore new ways to travel with Trip Rider</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non sollicitudin leo, et egestas diam.</p>
                    </div>
                </div>

                <div className='login2'>
                    <div className='login7'>
                        <p>Forget Password</p>
                        <h5>Enter the only super Admin email</h5>
                    </div>
                    <div className='login4'>
                        <div className='login20'>
                            <label htmlFor="">Email</label>
                            <input
                                type="email"
                                placeholder='ex. email@domain.com'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError(false);
                                }}
                                required
                            />
                            {emailError && <span className='login50'>Please input your email!</span>}
                        </div>
                        
                    </div>

                    <div className='login5' onClick={handleForgetPassword}>
                        <button>Send OTP</button>
                    </div>
                    <div className='login6'>
                        <span>Already have an account?</span>
                        <span onClick={() => navigate('/')}>Login Here</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
