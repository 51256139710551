import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, Polyline } from '@react-google-maps/api';

const containerStyle = {
    width: '1000px',
    height: '253px'
};



function MyComponent({ pickupLatitude, pickupLongitude, dropLatitude, dropLongitude }) {
    const pickupPosition = { lat: parseFloat(pickupLatitude), lng: parseFloat(pickupLongitude) };
    const dropPosition = { lat: parseFloat(dropLatitude), lng: parseFloat(dropLongitude) };



    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyB44XbRmsr64bo44DZvkwudZ9gF4aRqum8"
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={pickupPosition}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={pickupPosition} icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' }} />
            <Marker position={dropPosition} icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }} />
            <Polyline path={[pickupPosition, dropPosition]} options={{ strokeColor: '#FF0000' }} />
        </GoogleMap>
    ) : <></>;
}

export default React.memo(MyComponent);