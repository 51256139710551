import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './Wallet_Management.css'
import HOC from '../../Components/HOC/HOC'
import { toast } from 'react-toastify';

import { IoSearch } from "react-icons/io5";

import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import CustomPagination from '../../Components/Pagination/Pagination';



const Wallet_Management = () => {
    const [walletdata, setWalletData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const limit = 15;
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const fetchWalletData = useCallback(async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/getAllWalletTransaction?page=${page}&limit=${limit}&search=${search}`, getAuthHeaders());
            setWalletData(response.data.data.docs);
            setTotalPages(response.data.data.totalPages);
        } catch (error) {
            console.error('Error fetching wallet data:', error);
        }
        finally {
            setLoading(false);
        };
    }, [page, limit, search]);

    useEffect(() => {
        fetchWalletData();
    }, [fetchWalletData]);


    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };


    const handlePageChange = (newPage) => {
        if (newPage === page || newPage < 1 || newPage > totalPages) return;
        setPage(newPage); // This will trigger a re-fetch of data
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formattedDate = `${date.getDate().toString().padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

        return `${formattedDate} `;
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);


        let hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        const formattedTime = `${hours.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}${ampm}`;

        // Combine date and time
        return `${formattedTime} `;
    };

    const handleExport = () => {
        if (!fromDate || !toDate) {
            toast.error('Please select both From Date and To Date.');
            return;
        }

        const adjustedToDate = new Date(toDate);
        adjustedToDate.setDate(adjustedToDate.getDate() + 1);
        const formattedToDate = adjustedToDate.toISOString().split('T')[0];

        const exportUrl = `${BaseUrl}api/v1/downloadWalletTransaction?fromDate=${fromDate}&toDate=${formattedToDate}`;

        axios.get(exportUrl, getAuthHeaders())
            .then(response => {
                let downloadUrl = response.data.data;
                if (downloadUrl.startsWith('./')) {
                    downloadUrl = `https://tripriderapp.in/api${downloadUrl.slice(1)}`;
                }

                window.open(downloadUrl, '_blank');
            })
            .catch(error => {
                console.error('Error exporting data:', error);
                toast.error('Failed to export data. Please try again later.');
            });
    };

    const handleFromDate = (event) => setFromDate(event.target.value);
    const handleToDate = (event) => setToDate(event.target.value);


    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>Wallet Management</h6>
                        </div>

                        <div className='rider4'>
                            <div className="date-filters">
                                <label>
                                    From:
                                    <input type="date" value={fromDate} onChange={handleFromDate} />
                                </label>
                                <label>
                                    To:
                                    <input type="date" value={toDate} onChange={handleToDate} />
                                </label>
                            </div>
                            <button onClick={handleExport}>Export</button>

                            <div className='rider5'>
                                <div className='rider6'>
                                    <IoSearch />
                                </div>
                                <input type="search" name="" id="" placeholder='Search User'
                                    onChange={handleSearch} />
                            </div>
                        </div>
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Name</th>
                                    <th>Mobile Number</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Mode</th>
                                    <th>Cash In</th>
                                    <th>Wallet</th>
                                </tr>
                            </thead>
                            <tbody>

                                {loading ? (
                                    <tr>
                                        <td colSpan="8" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading wallet...</td>
                                    </tr>
                                ) : walletdata.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>detail not found</td>
                                    </tr>
                                ) : (
                                    walletdata?.map(Wallet => (
                                        <tr key={Wallet.id}>
                                            <td className='rider8'>{Wallet?.id}</td>
                                            <td>{Wallet?.user?.name || "User"}</td>
                                            <td>{Wallet?.user?.mobileNumber || "N/A"}</td>
                                            <td>{formatDate(Wallet.date)}</td>
                                            <td>{formatTime(Wallet.date)}</td>
                                            <td>{Wallet.paymentMode}</td>
                                            <td style={{ color: '#F52D56' }}>{Wallet.amount}</td>
                                            <td>{Wallet?.user?.wallet}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='rider_details555'>
                    <CustomPagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HOC(Wallet_Management);
