import React, { useEffect, useState } from 'react';
import './Login.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import img from '../../Images/logo.png';
import { BaseUrl } from '../../Components/BaseUrl/BaseUrl';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const OTPveify = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const [otp, setOTP] = useState('');
    const [newpassword, SetNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleotpverify = async () => {
        try {
            const response = await axios.post(`${BaseUrl}api/v1/admin/changePassword/${id}`, {
                otp: otp,
                newPassword: newpassword,
                confirmPassword: confirmPassword
            });

            // Check response status and show appropriate messages
            if (response.status === 200) {
                toast.success("Password Updated successfully");
                navigate('/');
            } else if (response.status === 201) {
                toast.info("Password not Matched");
            } 
        } catch (error) {
            console.error('Error:', error.message);

            if (error.response) {
                // Server responded with a status other than 2xx
                if (error.response.status === 400) {
                    toast.error('Invalid OTP');
                }
            } else {
                // No response from server
                toast.error('Network error. Please check your internet connection.');
            }
        }
    };


    return (
        <>
            <div className='login'>
                <div className='login1'>
                    <div className='login9'>
                        <img src={img} alt="" />
                    </div>
                    <div className='login10'>
                        <h3>Explore new ways to travel with Trip Rider</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non sollicitudin leo, et egestas diam.</p>
                    </div>
                </div>

                <div className='login2'>
                    <div className='login7'>
                        <p>OTP & New Password</p>
                        <h5>Enter the otp, new password and confirm Password</h5>
                    </div>
                    <div className='login4'>
                        <div className='login20'>
                            <label htmlFor="">OTP</label>
                            <input
                                type="text"
                                placeholder='otp'
                                value={otp}
                                onChange={(e) => {
                                    setOTP(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className='login20'>
                            <label htmlFor="">New password</label>
                            <input
                                type="text"
                                placeholder='new password'
                                value={newpassword}
                                onChange={(e) => {
                                    SetNewPassword(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className='login20'>
                            <label htmlFor="">Confirm password</label>
                            <input
                                type="text"
                                placeholder='Confirm password'
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                                required
                            />
                        </div>
                    </div>

                    <div className='login5' onClick={handleotpverify}>
                        <button>Submit</button>
                    </div>
                    {/* <div className='login6'>
                        <span>Don’t have an account?</span>
                        <span onClick={() => navigate('/register')}>Signup Here</span>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default OTPveify;
