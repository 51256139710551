import React, { useEffect, useState } from 'react';
import axios from 'axios'
import HOC from '../../Components/HOC/HOC'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';

import { RiDeleteBinLine } from "react-icons/ri";



// import img from '../../Images/img5.png'


const AddCoupon = () => {
    const [category, setCategory] = useState('');
    const [discount, setDiscount] = useState('');
    const [description, setDescription] = useState('');
    const [activationDate, setActivationDate] = useState(new Date().toISOString().split("T")[0]);
    const [expirationDate, setExpirationDate] = useState('');
    const [usersdata, setUserData] = useState([]);
    const [vehicledata, setVehicleData] = useState([]);
    const [ambulancedata, setAmbulanceData] = useState([]);
    const [supercardata, setSuperCarData] = useState([]);


    const [userid, setUserId] = useState([])
    const [vehicleid, setVehicleId] = useState(null)
    const [ambulanceid, setAmbulanceId] = useState(null)
    const [supercarid, setSuperCarId] = useState(null)


    const [limits, setLimits] = useState('');
    const [limitu, setLimitu] = useState('');


    const [total, setTotal] = useState('')

    const fetchData = async () => {
        try {
            const response2 = await axios.get(`${BaseUrl}api/v1/getSuperCarWithPaginate?page=1&limit=${limits}`, getAuthHeaders());
            const response3 = await axios.get(`${BaseUrl}api/v1/admin/all/user?page=1&limit=${limitu}`, getAuthHeaders());
            setLimits(response2.data.data.totalDocs)
            setLimitu(response3.data.data.totalDocs)
        } catch (error) {
            console.error('Error fetching driver name:', error);
        }
    };


    const fetchVehicle = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/vehicle`, getAuthHeaders());
            setVehicleData(response.data.data);
        } catch (error) {
            console.error('Error fetching vehicle name:', error);
        }
    };

    const fetchAmbulance = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/ambulanceVehicle/ForWebsite`, getAuthHeaders());
            setAmbulanceData(response.data.data);
        } catch (error) {
            console.error('Error fetching ambulance name:', error);
        }
    };
    const fetchSupercar = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/getSuperCarWithPaginate?limit=${limits}`, getAuthHeaders());
            setSuperCarData(response.data.data.docs);
        } catch (error) {
            console.error('Error fetching super car name:', error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/admin/all/user?limit=${limitu}`, getAuthHeaders());
            setUserData(response.data.data.docs);
        } catch (error) {
            console.error('Error fetching User name:', error);
        }
    };

    const handlePostRequest = async () => {
        const data = {
            user: userid,
            vehicleId: vehicleid,
            total: total,
            vehicleAmbulanceId: ambulanceid,
            superCar: supercarid,
            discountPer: discount,
            description: description,
            activationDate: activationDate,
            expirationDate: expirationDate,
        };

        try {
            const response = await axios.post(`${BaseUrl}api/v1/Coupon/addCoupon`, data, getAuthHeaders());
            const message = response.data.message;
            toast.success(message);
            setCategory('');
            setDiscount('');
            setDescription('');
            setActivationDate('');
            setExpirationDate('');
            setUserId(['']);
            setVehicleId('');
            setAmbulanceId('');
            setSuperCarId('');
            navigate('/coupon');
        } catch (error) {
            console.error('Error to coupan:', error);

            const errorMessage = error.response?.data?.message || 'Failed to coupan. Please try again later.';
            toast.error(errorMessage);
        }
    };



    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        fetchVehicle();
        fetchSupercar();
        fetchUser();
        fetchAmbulance();
    }, [limitu, limits]);

    const navigate = useNavigate()



    const handleUserSelection = (e) => {
        const selectedUser = e.target.value;
        if (selectedUser && !userid.includes(selectedUser)) {
            setUserId((prev) => [...prev, selectedUser]);
        }
    };

    const removeCity = (userToRemove) => {
        setUserId((prev) => prev.filter((user) => user !== userToRemove));
    };


    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>Add Coupon</h6>
                        </div>

                        <div className='rider4'>

                        </div>
                    </div>


                    <div className='dailyprice'>
                        <div className='outstationprice1'>
                            <p>Coupon For</p>
                            <div className='outstationprice2'>
                                <div className='outstationprice3'>
                                    <input type="radio"
                                        name="vehicle"
                                        value='vehicle'
                                        checked={category === "vehicle"}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                    <p>Vehicle</p>
                                </div>
                                <div className='outstationprice3'>
                                    <input type="radio"
                                        name="ambulance"
                                        value='ambulance'
                                        checked={category === "ambulance"}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                    <p>Ambulance</p>
                                </div>
                                <div className='outstationprice3'>
                                    <input type="radio"
                                        name="superCar"
                                        value='superCar'
                                        checked={category === "superCar"}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                    <p>SuperCar</p>
                                </div>
                                <div className='outstationprice3'>
                                    <input type="radio"
                                        name="all user"
                                        value='All'
                                        checked={total === "All"}
                                        onChange={(e) => setTotal(e.target.value)}
                                    />
                                    <p>All User</p>
                                </div>
                            </div>

                        </div>
                        <div className='dailyprice3'>
                            {total === "All" ? (
                                <>

                                </>
                            ) :
                                <>
                                    <div className="dailyprice2">
                                        <label htmlFor="users">Users</label>
                                        <select
                                            id="users"
                                            onChange={handleUserSelection}
                                            value=""
                                        >
                                            <option value="">Select the individual</option>
                                            {usersdata?.map((user) => (
                                                <option key={user._id} value={user._id}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="selected-cities">
                                        <h6>Selected Users:</h6>
                                        <ul>
                                            {userid.map((id) => {
                                                const user = usersdata.find((u) => u._id === id);
                                                return (
                                                    <li key={id}>
                                                        {user?.name}{' '}
                                                        <RiDeleteBinLine
                                                            className="delete-icon"
                                                            color="#667085"
                                                            size={20}
                                                            onClick={() => removeCity(id)}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </>
                            }
                            {category === "vehicle" ? (
                                <div className='dailyprice2'>
                                    <label htmlFor="">Vehicles</label>
                                    <select
                                        onChange={(e) => setVehicleId(e.target.value)}
                                        value={vehicleid}
                                    >
                                        <option value="">Select the individual</option>
                                        {vehicledata?.map((vehicle) => (
                                            <option key={vehicle._id} value={vehicle._id}>
                                                {vehicle.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )
                                : category === "ambulance" ? (
                                    <div className='dailyprice2'>
                                        <label htmlFor="">Ambulances</label>
                                        <select
                                            onChange={(e) => setAmbulanceId(e.target.value)}
                                            value={ambulanceid}
                                        >
                                            <option value="">Select the individual</option>
                                            {ambulancedata?.map((ambulance) => (
                                                <option key={ambulance._id} value={ambulance._id}>
                                                    {ambulance.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )
                                    : category === "superCar" ? (
                                        <div className='dailyprice2'>
                                            <label htmlFor="">Super Cars</label>
                                            <select
                                                onChange={(e) => setSuperCarId(e.target.value)}
                                                value={supercarid}
                                            >
                                                <option value="">Select the individual</option>
                                                {supercardata?.map((supercar) => (
                                                    <option key={supercar._id} value={supercar._id}>
                                                        {supercar.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )
                                        :
                                        (
                                            ""
                                        )
                            }

                            <div className='dailyprice4'>
                                <label htmlFor="">Discount</label>
                                <input type="number" placeholder='Enter Discount' value={discount} onChange={(e) => setDiscount(e.target.value)} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Activation Date</label>
                                <input
                                    type="date"
                                    placeholder="Enter Activation Date"
                                    value={activationDate}
                                    min={new Date().toISOString().split("T")[0]}
                                    onChange={(e) => setActivationDate(e.target.value)}
                                />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Expiration Date</label>
                                <input
                                    type="date"
                                    placeholder="Enter Expiration Date"
                                    value={expirationDate}
                                    min={activationDate || new Date().toISOString().split("T")[0]} // Expiration cannot be before Activation Date
                                    onChange={(e) => setExpirationDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='service111'>
                            <div className='service1'>
                                <label htmlFor="">Description</label>
                                <textarea name="" id="" rows="10" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </div>
                        </div>

                        <div className='promo1'>
                            <button onClick={() => navigate('/coupon')}>Cancel</button>
                            <button onClick={handlePostRequest}>Add Coupon</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HOC(AddCoupon)