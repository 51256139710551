// export const BaseUrl = "https://rajiv-cab-mu.vercel.app/";
// export const BaseUrl = "https://admin.flyweistechnologies.com/api/";
// export const BaseUrl = "http://103.54.101.213/api/";
// export const BaseUrl = "https://tripriderapp.in/api/";

export const BaseUrl = process.env.REACT_APP_API_URL;


export const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
