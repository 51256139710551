// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking{
    width: 312px;
    height: 44px;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 90px;
}

.booking p{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height:24px;
    letter-spacing: 0.5px;
    margin: 0;
}


.booking1{
    width: 30px;
    height: 30px;
    background-color: #C3052C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/All Bookings/Bookings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,SAAS;AACb;;AAEA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,qBAAqB;IACrB,SAAS;AACb;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".booking{\n    width: 312px;\n    height: 44px;\n    border: 1px solid #FFFFFF;\n    border-radius: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    gap: 90px;\n}\n\n.booking p{\n    font-family: \"Roboto\", sans-serif;\n    font-weight: 400;\n    font-size: 16px;\n    color: #FFFFFF;\n    line-height:24px;\n    letter-spacing: 0.5px;\n    margin: 0;\n}\n\n\n.booking1{\n    width: 30px;\n    height: 30px;\n    background-color: #C3052C;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
