// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminprofileupdate{
    background-color: #C3052C;
    /* background-color: #000000; */
    color: #FFFFFF;
}




.adminprofileupdate55{
    margin-top: 30px;
}




.adminprofileupdate56{
    display: flex;
    align-items: center;
    gap: 20px;
}


.adminprofileupdate57{
    display: flex;
    align-items: center;
    gap: 5px;
}


`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/AdminProfile.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,+BAA+B;IAC/B,cAAc;AAClB;;;;;AAKA;IACI,gBAAgB;AACpB;;;;;AAKA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".adminprofileupdate{\n    background-color: #C3052C;\n    /* background-color: #000000; */\n    color: #FFFFFF;\n}\n\n\n\n\n.adminprofileupdate55{\n    margin-top: 30px;\n}\n\n\n\n\n.adminprofileupdate56{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\n\n\n.adminprofileupdate57{\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
