// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar{
    width: auto;
    height: 100%;
    background-color: #080707;
}

.sidebar1{
    width: auto;
    background-color: #080707;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}


.sidebar2{
    padding-top: 10px;
    width: 150px;
    height: 150px;
}

.sidebar2 img{
    width: 100%;
    height: 100%;
}


.sidebar3{
}

.sidebar4{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.sidebar4 p{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
    margin-top: 20px;
}



.sidebar5{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
}




.sidebar-link{
    text-decoration: none; 
    color: inherit; 
}`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;AACxB;;;AAGA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;AACA;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,SAAS;IACT,gBAAgB;AACpB;;;;AAIA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;;;;AAKA;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".sidebar{\n    width: auto;\n    height: 100%;\n    background-color: #080707;\n}\n\n.sidebar1{\n    width: auto;\n    background-color: #080707;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 50px;\n}\n\n\n.sidebar2{\n    padding-top: 10px;\n    width: 150px;\n    height: 150px;\n}\n\n.sidebar2 img{\n    width: 100%;\n    height: 100%;\n}\n\n\n.sidebar3{\n}\n\n.sidebar4{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    cursor: pointer;\n}\n\n.sidebar4 p{\n    font-family: \"Poppins\", sans-serif;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    color: #FFFFFF;\n    margin: 0;\n    margin-top: 20px;\n}\n\n\n\n.sidebar5{\n    font-weight: 500;\n    font-size: 18px;\n    line-height: 24px;\n    color: #FFFFFF;\n    margin-top: 20px;\n}\n\n\n\n\n.sidebar-link{\n    text-decoration: none; \n    color: inherit; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
