// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocked-vendor {
    background-color: rgb(255, 96, 96); 
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Pages/All Vendors/Vendors.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;AAChB","sourcesContent":[".blocked-vendor {\n    background-color: rgb(255, 96, 96); \n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
