// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container1 {
  display: flex;
  /* height: 100vh; */
}

.sidebar55 {
  width: 256px;
  flex: 0 0 auto;
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.child-component {
  flex: 1 1;
  padding: 20px;
  overflow: scroll;
}

.upperbar {
  padding: 10px;
}

.sidebar55-hidden .content {
  flex: 1 1 100%; 

}




`, "",{"version":3,"sources":["webpack://./src/Components/HOC/HOC.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;;AAEhB","sourcesContent":[".container1 {\n  display: flex;\n  /* height: 100vh; */\n}\n\n.sidebar55 {\n  width: 256px;\n  flex: 0 0 auto;\n}\n\n.content {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n\n.child-component {\n  flex: 1;\n  padding: 20px;\n  overflow: scroll;\n}\n\n.upperbar {\n  padding: 10px;\n}\n\n.sidebar55-hidden .content {\n  flex: 1 1 100%; \n\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
