import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import HOC from '../../../Components/HOC/HOC'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl, getAuthHeaders } from '../../../Components/BaseUrl/BaseUrl';
import Modal from "react-bootstrap/Modal";
import img1 from '../../../Images/imgno.jpg'



import img3 from '../../../Images/img43.png';



// import img from '../../Images/img5.png'


const ViewSuperCarPricing = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [previewImages, setPreviewImages] = useState([]);
    const [price, setPrice] = useState('');
    const [kmLimit, setKmLimit] = useState('');
    const [kmPrice, setKmPrice] = useState('')
    const [hrPrice, setHrPrice] = useState('');
    const [hrLimit, setHrLimit] = useState('');
    const [pricePerKmGreater, setPricePerKmGreater] = useState('');
    const [pricePerMinGreater, SetPricePerMinGreater] = useState('');
    const [modalShow1, setModalShow1] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');




    useEffect(() => {
        fetchSuperCarDetails();
    }, [id]);
    const fetchSuperCarDetails = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/SuperCarPricing/${id}`, getAuthHeaders());
            const { name, image, price, kmLimit, kmPrice, hrPrice, hrLimit, pricePerKmGreater, pricePerMinGreater } = response.data.data;
            setName(name);
            setPreviewImages(image.map(imgObj => ({ img: imgObj.img, id: imgObj._id })));
            setPrice(price);
            setKmLimit(kmLimit);
            setKmPrice(kmPrice);
            setHrPrice(hrPrice);
            setHrLimit(hrLimit);
            setPricePerKmGreater(pricePerKmGreater);
            SetPricePerMinGreater(pricePerMinGreater);
        } catch (error) {
            console.error('Error fetching Super car Pricing details:', error);
        }
    };





    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);
        setModalShow1(true);
    };

    function ImageViewer(props) {
        const [imageUrl, setImageUrl] = useState("");

        useEffect(() => {
            if (props.show === true) {
                setImageUrl(props?.imageUrl);
            }
        }, [props])

        // const isPDF = (url) => {
        //     return typeof url === 'string' && url.toLowerCase().endsWith('.pdf');
        // };

        // const isPDF = (url) => {
        //     return url && url.toLowerCase().endsWith('.pdf');
        // };


        const isPDF = (url) => {
            if (url instanceof File) {
                return url.type === 'application/pdf';
            } else if (typeof url === 'string') {
                return url.toLowerCase().endsWith('.pdf');
            }
            return false;
        };



        // Function to handle image download
        const downloadFile = async (url, fileName) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } catch (error) {
                toast.error("Error downloading the file");
                console.error('Error downloading the file:', error);
            }
        };

        const handleDownloadClick = () => {
            const fileName = imageUrl.split('/').pop();
            downloadFile(imageUrl, fileName);
        };



        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='adminprofileupdate'>
                    <Modal.Title id="contained-modal-title-vcenter">View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-overlay">
                        <div className='rider4'>
                            <button onClick={handleDownloadClick} style={{ marginBottom: '10px' }}>Download Image</button>
                        </div>
                        <div className="modal-content">
                            <img src={imageUrl instanceof File ? URL.createObjectURL(imageUrl) : imageUrl || img1} alt="Full Image" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const deleteimg = (imgId) => {
        axios.delete(`${BaseUrl}api/v1/SuperCarPricing/Image/${id}?imageId=${imgId}`)
            .then(response => {
                fetchSuperCarDetails();
                toast.success("Image deleted successfully");
            })
            .catch(error => {
                console.error('Error deleting Image:', error);
                toast.error("Failed to delete Image. Please try again later.");
            });
    }

    const navigate = useNavigate()
    return (
        <>
            <ImageViewer
                show={modalShow1}
                onHide={() => setModalShow1(false)}
                imageUrl={currentImageUrl}
            />
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>Super Car Pricing</h6>
                        </div>

                        <div className='rider4'>
                            <button onClick={() => navigate('/allsupercarpricing')}>Back</button>
                        </div>
                    </div>


                    <div className='dailyprice'>
                        <div className='dailyprice3'>
                            <div className='dailyprice4'>
                                <label htmlFor="">Super car Name</label>
                                <input type="text" placeholder='Enter Name' value={name} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Price</label>
                                <input type="number" placeholder='Enter Price' value={price} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">km Limit</label>
                                <input type="number" placeholder='Enter Km Limit' value={kmLimit} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">km Price</label>
                                <input type="number" placeholder='Enter Km Price' value={kmPrice} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Hours Limit</label>
                                <input type="number" placeholder='Enter Hours limit' value={hrLimit} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Hours Price</label>
                                <input type="number" placeholder='Enter Hours Price' value={hrPrice} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Price/KmGreater</label>
                                <input type="number" placeholder='Enter Price/KmGreater' value={pricePerKmGreater} />
                            </div>
                            <div className='dailyprice4'>
                                <label htmlFor="">Price/MinGreater</label>
                                <input type="number" placeholder='Enter Price/MinGreater' value={pricePerMinGreater} />
                            </div>
                        </div>


                        <div className='rider_details12'>
                            <div className='rider_details12114'>
                                <h6>SuperCar's All Images</h6>
                            </div>

                            <div className='rider_details20'>
                                {previewImages?.map(img => (
                                    <div className='rider_details2122' key={img?.id}>
                                        <div className='rider_details21'  >
                                            <input type="file" id="fileInput1" style={{ display: 'none' }} />
                                            <div onClick={() => handleImageClick(img.img)} className='rider_details213'>
                                                <img
                                                    src={img.img instanceof File ? URL.createObjectURL(img.img) : img.img || img1}
                                                    alt={img !== img ? " Image" : "No Image"}
                                                />
                                            </div>
                                        </div>
                                        <div className='rider4'>
                                            <button onClick={() => deleteimg(img?.id)}>Delete</button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className='promo1'>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HOC(ViewSuperCarPricing)