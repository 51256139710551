import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './AllBookings.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HOC from '../../Components/HOC/HOC'

import { IoSearch } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";

import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import CustomPagination from '../../Components/Pagination/Pagination';



// import img from '../../Images/img5.png'


const AllBookings = () => {
    const location = useLocation();
    const [bookingData, setBookingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(location.state?.page || 1);
    const [limit, setLimit] = useState(12);
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const cachedAdminData = localStorage.getItem('adminData');
    const adminData = JSON.parse(cachedAdminData);
    const role = localStorage.getItem('role');

    let permissionsArray = [];

    if (adminData && adminData.permissions) {
        permissionsArray = adminData.permissions;
    } else {
        console.log('Permissions array not found in adminData.');
    }


    const fetchBookingData = useCallback(() => {
        axios.get(`${BaseUrl}api/v1/getBooking?page=${page}&limit=${limit}&search=${search}`, getAuthHeaders())
            .then(response => {
                setBookingData(response.data.data.docs);
                setTotalPages(response.data.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching Bookings data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [page, limit, search]);


    useEffect(() => {
        fetchBookingData();
        const interval = setInterval(() => {
            fetchBookingData();
        }, 30000); // 10000ms = 10 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [fetchBookingData]);

    const handlePageChange = (newPage) => {
        if (newPage === page || newPage < 1 || newPage > totalPages) return;

        setPage(newPage); // This will trigger a re-fetch of data
    };


    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };



    const navigate = useNavigate();

    const handleExport = () => {
        if (!fromDate || !toDate) {
            toast.error('Please select both From Date and To Date.');
            return;
        }

        const adjustedToDate = new Date(toDate);
        adjustedToDate.setDate(adjustedToDate.getDate() + 1);
        const formattedToDate = adjustedToDate.toISOString().split('T')[0];

        const exportUrl = `${BaseUrl}api/v1/downloadBooking?fromDate=${fromDate}&toDate=${formattedToDate}`;

        axios.get(exportUrl, getAuthHeaders())
            .then(response => {
                let downloadUrl = response.data.data;
                if (downloadUrl.startsWith('./')) {
                    downloadUrl = `https://tripriderapp.in/api${downloadUrl.slice(1)}`;
                }

                window.open(downloadUrl, '_blank');
            })
            .catch(error => {
                console.error('Error exporting data:', error);
                toast.error('Failed to export data. Please try again later.');
            });
    };

    const handleFromDate = (event) => setFromDate(event.target.value);
    const handleToDate = (event) => setToDate(event.target.value);


    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>All Booking</h6>
                        </div>

                        <div className='rider4'>
                            <div className="date-filters">
                                <label>
                                    From:
                                    <input type="date" value={fromDate} onChange={handleFromDate} />
                                </label>
                                <label>
                                    To:
                                    <input type="date" value={toDate} onChange={handleToDate} />
                                </label>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                <button onClick={handleExport}>Export</button>
                                <button onClick={() => navigate(-1)}>Back</button>
                            </div>
                            <div className='rider5'>
                                <div className='rider6'>
                                    <IoSearch />
                                </div>
                                <input type="search" name="" id=""
                                    placeholder='Search booking'
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='rider7'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Booking ID</th>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Driver</th>
                                    <th>Vendor</th>
                                    <th>Timing</th>
                                    <th>Distance</th>
                                    <th>(₹)Total Bill</th>
                                    <th>Vehicle Name</th>
                                    <th>Status</th>
                                    <th>Action Buttons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="11" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading bookings...</td>
                                    </tr>
                                ) : bookingData.length === 0 ? ( // Check if filtered data is empty
                                    <tr>
                                        <td colSpan="11" style={{ color: "#C3052C", fontWeight: "600", fontSize: "18px" }}>No bookings found.</td>
                                    </tr>
                                ) : (
                                    bookingData.map(booking => (
                                        <tr key={booking.id}>
                                            <td>{booking.bookingId}</td>
                                            <td>{booking?.date}</td>
                                            <td>{booking?.userId?.name}</td>
                                            <td>{booking?.driver?.name}</td>
                                            <td>{booking?.vendorId?.name}</td>
                                            <td>{booking?.time}</td>
                                            <td>{booking?.distance} Km</td>
                                            <td>{booking?.totalPrice}</td>
                                            <td>
                                                {booking?.serviceType === 'superCar' ? (
                                                    booking?.superCar?.name

                                                ) : booking?.serviceType === 'ambulance' ? (
                                                    booking?.vehicleAmbulance?.name
                                                ) : (
                                                    booking?.car?.name
                                                )
                                                }
                                            </td>
                                            {booking?.status === "autoCancelled" ?
                                                <td style={{
                                                    color: booking?.status === 'cancel' ? '#F52D56' :
                                                        booking?.status === 'autoCancelled' ? 'red' :
                                                            booking?.status === 'pending' ? '#FBAC2C' :
                                                                booking?.status === 'complete' ? '#609527' : 'black',
                                                    fontWeight: '600'
                                                }}>
                                                    {booking?.status === "autoCancelled" ? "autoCancelled" : ""}
                                                </td>
                                                :
                                                <td
                                                    style={{
                                                        color:
                                                            booking?.isCompleted && !booking?.isPayment
                                                                ? "#FF8800" // Orange for Payment Pending
                                                                : booking?.isCompleted && booking?.isPayment
                                                                    ? "#609527"
                                                                    : booking?.isAccepted
                                                                        ? "#1D4ED8"
                                                                        : booking?.isCancelled
                                                                            ? "#FF0000"
                                                                            : booking?.isPending
                                                                                ? "#FBAC2C"
                                                                                : "black",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {
                                                        booking?.isCompleted && !booking?.isPayment
                                                            ? "Payment Pending"
                                                            : booking?.isCompleted && booking?.isPayment
                                                                ? "Completed"
                                                                : booking?.isAccepted
                                                                    ? "Accepted"
                                                                    : booking?.isCancelled
                                                                        ? "Cancelled"
                                                                        : booking?.isPending
                                                                            ? "Pending"
                                                                            : "Unknown"}
                                                </td>
                                            }


                                            {/* <td style={{
                                                color: booking?.status === 'cancel' ? '#F52D56' :
                                                    booking?.status === 'autoCancelled' ? 'red' :
                                                        booking?.status === 'pending' ? '#FBAC2C' :
                                                            booking?.status === 'complete' ? '#609527' : 'black',
                                                fontWeight: '600'
                                            }}>
                                                {booking?.status}
                                            </td> */}
                                            <td>
                                                <div className='rider9'>
                                                    {role === 'superAdmin' ? (
                                                        <div className='rider10'>
                                                            <Link
                                                                to={`/bookingdetails/${booking._id}`}
                                                                className='sidebar-link'
                                                                state={{ page }}
                                                            >
                                                                <IoEyeOutline color='#667085' size={20} />
                                                                <p>View</p>
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {permissionsArray.some(permission => permission.name === 'Bookings' && permission.view) && (
                                                                <div className='rider10'>
                                                                    <Link
                                                                        to={`/bookingdetails/${booking._id}`}
                                                                        className='sidebar-link'
                                                                        state={{ page }}
                                                                    >
                                                                        <IoEyeOutline color='#667085' size={20} />
                                                                        <p>View</p>
                                                                    </Link>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='rider_details555'>
                    <CustomPagination
                        page={page}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HOC(AllBookings)