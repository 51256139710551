import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './LiveChart.css'
import HOC from '../../Components/HOC/HOC'
import { collection, query, orderBy, getDocs, limit, addDoc, onSnapshot, updateDoc, where } from 'firebase/firestore';
import { db, auth } from "../../Components/Firebase/Firebase";
import { BaseUrl, getAuthHeaders } from '../../Components/BaseUrl/BaseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

import { format, isToday, isYesterday } from 'date-fns';

// import plus from '../../Images/Vector.png'
import send from '../../Images/send.png'
import img2 from '../../Images/user.webp'




const LiveChartWithDriver = () => {
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selecteddriver, setSelectedDriver] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(true);
    const [limitd, setLimitd] = useState('');
    const [search, setSearch] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [unreadMessages, setUnreadMessages] = useState({});
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [groupedMessages, setGroupedMessages] = useState([]);


    const messageContainerRef = useRef(null);


    const fetchData = async () => {
        try {
            const response1 = await axios.get(`${BaseUrl}api/v1/admin/all/driver?page=1&limit=${limitd}`, getAuthHeaders());
            setLimitd(response1.data.data.totalDocs)
        } catch (error) {
            console.error('Error fetching driver name:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const scrollToBottom = () => {
        if (messageContainerRef.current && isAtBottom) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };


    const handleScroll = () => {
        const container = messageContainerRef.current;
        if (container) {
            setIsAtBottom(container.scrollHeight - container.scrollTop <= container.clientHeight);
        }
    };


    const fetchMessages = async () => {
        try {
            if (!selecteddriver || !selecteddriver._id) return;
            const messagesRef = collection(db, 'chatwithadmin', selecteddriver._id, 'messages');
            const q = query(messagesRef, orderBy('timestamp', 'asc'));
            const querySnapshot = await getDocs(q);

            const allMessages = [];
            querySnapshot.forEach(doc => {
                allMessages.push({ id: doc.id, ...doc.data() });
            });
            setMessages(allMessages);
            const updatedUnreadMessages = allMessages.reduce((acc, message) => {
                if (message.read === false && message.type === 'user') {
                    acc[message.id] = true;
                }
                return acc;
            }, {});
            setUnreadMessages(updatedUnreadMessages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    useEffect(() => {
        const grouped = messages.reduce((acc, message) => {
            let messageDate;

            if (message?.timestamp) {
                // Handle Firestore Timestamp
                if (typeof message.timestamp.toMillis === 'function') {
                    messageDate = new Date(message.timestamp.toMillis());
                }
                // Handle ISO string
                else if (typeof message.timestamp === 'string') {
                    messageDate = new Date(message.timestamp);
                }
                // Handle JavaScript Date object
                else if (message.timestamp instanceof Date) {
                    messageDate = message.timestamp;
                }
            }

            // Format the date to group messages
            const formattedDate = messageDate ? messageDate.toDateString() : 'Unknown Date';

            if (!acc[formattedDate]) acc[formattedDate] = [];
            acc[formattedDate].push(message);
            return acc;
        }, {});

        setGroupedMessages(Object.entries(grouped));
    }, [messages]);

    useEffect(() => {
        if (selecteddriver) {
            fetchMessages();
        }
        scrollToBottom();
    }, [selecteddriver, messages, groupedMessages]);




    const fetchDriverData = useCallback(async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/admin/all/driver?page=${page}&limit=${limitd}&search=${search}`, getAuthHeaders());
            const driverData = response?.data?.data?.docs;
            setTotalPages(response.data.data.totalPages);


            const updatedDriver = await Promise.all(driverData.map(async user => {
                const messagesRef = collection(db, 'chatwithadmin', user._id, 'messages');
                const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(1));
                const querySnapshot = await getDocs(q);
                const lastMessageDoc = querySnapshot.docs[0];
                const lastMessage = lastMessageDoc ? lastMessageDoc.data().message : '';
                const lastMessageTime = lastMessageDoc ? lastMessageDoc.data().timestamp : null;

                return { ...user, lastMessage, lastMessageTime };
            }));

            setDrivers(updatedDriver);
        } catch (error) {
            console.error('Error fetching driver data:', error);
        }
        finally {
            setLoading(false);
        };
    }, [page, limitd, search]);


    const fetchAdminData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}api/v1/admin/me`, getAuthHeaders())
            const AdminName = response.data.data.name;
            const AdminImage = response.data.data.profilePicture;
            setName(AdminName);
            setImage(AdminImage);
        } catch (error) {
            console.error('Error fetching Admin data:', error);
        }
    };



    useEffect(() => {
        fetchDriverData();
        fetchAdminData();
    }, [limitd, search, page]);


    const markMessagesAsReadInFirebase = async (userId) => {
        try {
            const messagesRef = collection(db, 'chatwithadmin', userId, 'messages');
            const q = query(messagesRef, orderBy('timestamp', 'asc'));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                if (doc.data().read === false && doc.data().type === 'user') {
                    const messageRef = doc.ref;
                    await updateDoc(messageRef, { read: true });
                }
            });
        } catch (error) {
            console.error('Error updating messages:', error);
        }
    };


    useEffect(() => {
        const unsubscribeMessages = onSnapshot(collection(db, 'chatwithadmin'), (snapshot) => {
            const newMessages = [];
            snapshot.forEach((doc) => {
                newMessages.push(doc.data());
            });
            setMessages(newMessages);
        });

        return () => unsubscribeMessages();
    }, []);


    useEffect(() => {
        const sorteddriver = [...drivers].sort((a, b) => {
            if (!a.lastMessageTime) return 1;
            if (!b.lastMessageTime) return -1;
            return b.lastMessageTime - a.lastMessageTime;
        });

        if (!_isEqual(sorteddriver, drivers)) {
            setDrivers(sorteddriver);
        }
    }, [messages]);

    useEffect(() => {
        const unsubscribeAll = onSnapshot(
            collection(db, 'chatwithadmin'),
            async (snapshot) => {
                for (const doc of snapshot.docs) {
                    const userMessagesCollection = collection(db, 'chatwithadmin', doc.id, 'messages');
                    const userMessagesQuery = query(userMessagesCollection, where('read', '==', false), where('type', '==', 'user'));

                    const userMessagesSnapshot = await getDocs(userMessagesQuery);
                    userMessagesSnapshot.forEach((messageDoc) => {
                        const messageData = messageDoc.data();
                        // showNotification("New Message", messageData.message);
                        setUnreadMessages((prev) => ({ ...prev, [doc.id]: true }));
                    });
                }
            }
        );

        return () => unsubscribeAll();
    }, []);


    useEffect(() => {
        if (selecteddriver) {
            const markMessagesAsRead = async () => {
                const querySnapshot = await getDocs(
                    collection(db, 'chatwithadmin', selecteddriver._id, 'messages')
                );
                querySnapshot.forEach((doc) => {
                    if (doc.data().type === 'user' && doc.data().read === false) {
                        updateDoc(doc.ref, { read: true });
                    }
                });
            };

            markMessagesAsRead();
        }
    }, [selecteddriver]);













    const handleSearch = (event) => {
        setPage(1);
        setSearch(event.target.value);
    };

    const handleDriverClick = (driver) => {
        setSelectedDriver(driver);
        setMessages([]);
        setUnreadMessages((prev) => {
            const updatedUnreadMessages = { ...prev };
            Object.keys(updatedUnreadMessages).forEach((messageId) => {
                if (updatedUnreadMessages[messageId]) {
                    updatedUnreadMessages[messageId] = false;
                }
            });
            return updatedUnreadMessages;
        });

        markMessagesAsReadInFirebase(driver._id);
    };


    const handleSendMessage = async () => {
        if (!selecteddriver || !newMessage.trim()) return;

        try {
            // Add the new message to Firebase
            const messagesRef = collection(db, 'chatwithadmin', selecteddriver._id, 'messages');
            const newMessageDoc = {
                message: newMessage,
                type: 'admin',
                image: image,
                name: name,
                timestamp: new Date(),
                read: false
            };
            await addDoc(messagesRef, newMessageDoc);

            setMessages(prevMessages => [newMessageDoc, ...prevMessages]);
            await fetchDriverData();

            setNewMessage('');
            if (selecteddriver.deviceToken) {
                await sendPushNotification(selecteddriver.deviceToken, newMessage);
            } else {
                console.warn("Selected driver does not have a device token");
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    const sendPushNotification = async (deviceToken, message) => {
        try {
            const response = await axios.post(`${BaseUrl}api/v1/user/sendMessage/ThroughPushNotification`, {
                deviceToken,
                title: "Message from Admin",
                body: message
            }, getAuthHeaders());

            if (response.status === 200) {
                console.log("Push notification sent successfully");
            } else {
                console.error("Failed to send push notification", response.data);
            }
        } catch (error) {
            console.error("Error sending push notification:", error);
        }
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ''; // Handle case where timestamp is null or undefined

        const date = timestamp.toDate();

        const currentDate = new Date();

        const timeDifference = currentDate - date;
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

        if (timeDifference < oneDayInMilliseconds) {
            return formatTime(date);
        } else {
            return formatDate(date);
        }
    };

    const formatDateHeader = (dateString) => {
        const date = new Date(dateString);
        if (isToday(date)) return "Today";
        if (isYesterday(date)) return "Yesterday";
        return format(date, 'MMMM d, yyyy');
    };
    const getFormattedTime = (timestamp) => {
        let date;

        // Check if timestamp is a Firebase Timestamp object
        if (timestamp && typeof timestamp.toMillis === 'function') {
            date = new Date(timestamp.toMillis());
        }
        // Check if it's already a JavaScript Date object
        else if (timestamp instanceof Date) {
            date = timestamp;
        }
        // Check if it's a string (ISO date format)
        else if (typeof timestamp === 'string') {
            date = new Date(timestamp);
        }
        // If timestamp is null/undefined or invalid
        else {
            console.error('Invalid timestamp:', timestamp);
            return '';
        }

        // Format the date and time (use date-fns or any preferred method)
        return format(date, 'hh:mm a'); // Example: 02:30 PM
    };
    const formatTime = (date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };



    return (
        <>
            <div className='rider'>
                <div className='rider1'>
                    <div className='rider2'>
                        <div className='rider3'>
                            <h6>Live Chat With Driver</h6>
                        </div>
                        <div className='rider4'>
                            <button onClick={() => navigate('/livechart')}>Chat with user</button>
                        </div>
                    </div>

                    <div className='livechart'>
                        <div className='livechart1'>
                            <div className='livechart2'>
                                <div className='livechart3'>
                                    <h5>Messages</h5>
                                    {/* <IoIosArrowDown color='#000000' size={20} />
                                    <p>{totalNewMessages}</p> */}
                                </div>

                                {/* <div className='livechart4'>
                                    <img src={plus} alt="" />
                                </div> */}
                            </div>

                            <div className='livechart6'>
                                <hr />
                            </div>

                            <div className='livechart5'>
                                <input type="search" placeholder='Search driver'
                                    onChange={handleSearch}
                                />
                            </div>


                            <div className='livechart6' >
                                {loading ? (
                                    <tr>
                                        <td colSpan="7" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Loading drivers...</td>
                                    </tr>
                                ) : drivers.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" style={{ color: "#b20202", fontWeight: "600", fontSize: "18px" }}>Drivers not found</td>
                                    </tr>
                                ) : (drivers.map(driver => (
                                    <div className='livechart7' key={driver?.id} onClick={() => handleDriverClick(driver)}>
                                        <div className='livechart8'>
                                            <div className='livechart852'>
                                                <img src={driver?.profilePicture || img2} alt="No image" style={{ width: '60px', height: "60px", borderRadius: "100%" }} />
                                            </div>
                                            <div className='livechart9'>
                                                <h6>{driver?.name?.length > 15 ? `${driver?.name?.substring(0, 15)}....` : driver?.name || "driver"}</h6>
                                                <p style={{ fontWeight: unreadMessages[driver._id] ? 'bold' : 'normal' }}>
                                                    {driver?.lastMessage?.length > 15 ? `${driver?.lastMessage?.substring(0, 15)}....` : driver?.lastMessage || "lastMessage"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='livechart10'>
                                            <p>{formatTimestamp(driver?.lastMessageTime)}</p>
                                        </div>
                                    </div>
                                ))
                                )}
                            </div>

                        </div>

                        <div className='livechart11'>
                            {selecteddriver ? (
                                <div className='livechart12'>

                                    <div className='livechart13'>
                                        <div className='livechart14'>
                                            <img src={selecteddriver?.profilePicture || img2} alt="No image" style={{ width: '60px', height: "60px", borderRadius: "100%" }} />
                                        </div>
                                        <div className='livechart15'>
                                            <h6>{selecteddriver?.name?.length > 15 ? `${selecteddriver?.name?.substring(0, 15)}....` : selecteddriver?.name}</h6>
                                        </div>
                                    </div>




                                    <div className='livechart18' ref={messageContainerRef} onScroll={handleScroll}>
                                        {groupedMessages.map(([date, messagesForDate]) => (
                                            <React.Fragment key={date}>
                                                {/* Date Header */}
                                                <div className="date-header">
                                                    <p>{formatDateHeader(date)}</p>
                                                </div>
                                                {/* Messages */}
                                                {messagesForDate.map(message => (
                                                    <div className={`${message.type === 'user' ? 'livechart19' : 'livechart24'}`} key={message.id}>
                                                        {message.type === 'user' && (
                                                            <div className='livechart20'>
                                                                {/* Optional user image */}
                                                                {/* <img src={message.image} alt="" /> */}
                                                            </div>
                                                        )}
                                                        <div className='livechart21'>
                                                            <div className={`${message.type === 'user' ? 'livechart22' : 'livechart23'}`}>
                                                                <p>{message.message}</p>
                                                                <span>{getFormattedTime(message.timestamp)}</span>
                                                            </div>
                                                        </div>
                                                        {message.type === 'admin' && (
                                                            <div className='livechart20'>
                                                                {/* Optional admin image */}
                                                                {/* <img src={message.image} alt="" /> */}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </React.Fragment>
                                        ))}

                                    </div>

                                    <div className='livechart25'>
                                        <div className='livechart28'>
                                            <input
                                                type="text"
                                                placeholder='Type a message'
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                            <img src={send} alt="" onClick={handleSendMessage} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='no-messages'>
                                    <h6>Please select a driver to view messages.</h6>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default HOC(LiveChartWithDriver)